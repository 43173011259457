$profile-panel-padding-top-bottom: 3.625rem;
$profile-panel-padding-left-right: calc(74vw / 1440 * 100);

///----------------------
$font-weight-semibold: 500;
$font-weight-light: 300;
$font-weight-400: 400;

//KK-LandingPage
$font-size-base_kk: 1rem; // KK
$font-size-sm_kk: $font-size-base_kk * 1.1;
$font-size-lg_kk: $font-size-base_kk * 1.23;
$line-height-lg_kk: 160%;
$font-size-xl_kk: $font-size-base_kk * 1.31;
$line-height-xl_kk: 160%;

// -- Paddings and Margins ------------------------------------------------
$vertical-margin-for-navbar-and-footer: 2rem;
